
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import BpVideoCategoryEditorVue from '@/components/video/BpVideoCategoryEditor.vue';
import { Category, useVideoStore } from '@/stores/video';

export default defineComponent({
  name: 'video-category-editor',
  components: {
    BpVideoCategoryEditor: BpVideoCategoryEditorVue
  },
  setup() {
    const router = useRouter();

    const store = useVideoStore(true);

    const category = ref<Category>();

    function loadVideo() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        category.value = doc as Category;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadVideo()
    }, { immediate: true });

    return {
      category,
      store,
    }
  }
});
