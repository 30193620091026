import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"opacity":"0.5"}
}
const _hoisted_2 = { style: {"opacity":"0.5"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_wysiwyg_editor = _resolveComponent("bp-wysiwyg-editor")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createBlock(_component_bp_card, {
    class: "bp-video-category-editor",
    loading: _ctx.loading,
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode("New category")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_translate, {
            key: 1,
            tag: "h2",
            "translate-params": { category: _ctx.getTranslated(_ctx.category.name) }
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode("Edit category \"%{category}\"")
            ])),
            _: 1
          }, 8, ["translate-params"]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        disabled: _ctx.saveDisabled,
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["disabled", "errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.video.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.category)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "bp-video-category-editor__form",
            onSubmit: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Name'),
              onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('name', _ctx.$gettext('Name'), $event))),
              required: "",
              maxlength: 200,
              modelValue: _ctx.category.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.category.name) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_select, {
              data: _ctx.store.getParents({ excludeIds: _ctx.category._id, includeRoot: true }).map(category => ({ ...category, icon: ['far', 'folder']})),
              label: _ctx.$gettext('Parent category'),
              "search-property": `name.${_ctx.currentLanguageISOString()}`,
              "sort-property": _ctx.parentSortProperty,
              onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('parent_id', _ctx.$gettext('Parent category'), $event))),
              required: "",
              modelValue: _ctx.category.parent_id,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.category.parent_id) = $event))
            }, {
              option: _withCtx(({ option }) => [
                _createVNode(_component_bp_icon, {
                  icon: option.icon
                }, null, 8, ["icon"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.getTranslated(option.name)), 1),
                (_ctx.store.getPathById(option._id).length > 0)
                  ? (_openBlock(), _createElementBlock("em", _hoisted_1, [
                      _cache[19] || (_cache[19] = _createTextVNode(" (")),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getPathById(option._id), (category, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: category._id
                        }, [
                          (index !== 0)
                            ? (_openBlock(), _createBlock(_component_bp_icon, {
                                key: 0,
                                class: "ml-sm mr-sm",
                                icon: "angle-right"
                              }))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(_ctx.getTranslated(category.name)), 1)
                        ], 64))
                      }), 128)),
                      _cache[20] || (_cache[20] = _createTextVNode(") "))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              "option-root": _withCtx(({ option }) => [
                _createVNode(_component_bp_icon, {
                  icon: _ctx.slashIcon(option.icon)
                }, null, 8, ["icon"]),
                _createElementVNode("em", _hoisted_2, "(" + _toDisplayString(_ctx.getTranslated(option.name)) + ")", 1)
              ]),
              _: 1
            }, 8, ["data", "label", "search-property", "sort-property", "modelValue"]),
            _createVNode(_component_bp_wysiwyg_editor, {
              label: _ctx.$gettext('Description'),
              onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('description', _ctx.$gettext('Description'), $event))),
              type: "textarea",
              maxlength: 500,
              modelValue: _ctx.category.description,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.category.description) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_wysiwyg_editor, {
              label: _ctx.$gettext('Hint'),
              onChangeValid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setError('hint', _ctx.$gettext('Hint'), $event))),
              type: "textarea",
              maxlength: 500,
              modelValue: _ctx.category.hint,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.category.hint) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Date'),
              onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('date', _ctx.$gettext('Date'), $event))),
              type: "date",
              modelValue: _ctx.category.date,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.category.date) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Order'),
              onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('order', _ctx.$gettext('Order'), $event))),
              type: "number",
              modelValue: _ctx.category.order,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.category.order) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_select, {
              data: _ctx.store.getTags().map(tag => ({ ...tag, icon: 'hashtag' })),
              label: _ctx.$gettext('Tags'),
              onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('tags', _ctx.$gettext('Tags'), $event))),
              clearable: "",
              "create-new-on-enter": "",
              multiple: "",
              "search-property": "name",
              modelValue: _ctx.category.tags,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.category.tags) = $event))
            }, null, 8, ["data", "label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Feature latest video'),
              text: _ctx.$gettext('Feature the latest video at the top of the video area.'),
              onChangeValid: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setError('featured', _ctx.$gettext('Feature latest video'), $event))),
              flush: "",
              type: "checkbox",
              modelValue: _ctx.category.featured,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.category.featured) = $event))
            }, null, 8, ["label", "text", "modelValue"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}